.App {
  text-align: center;
  background-color: #f3f4f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* TipTap Editor */
.ProseMirror {}




.ProseMirror > * + * {
    margin-top: 0.75em;
  }

  .ProseMirror p.is-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float:left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror ul {
    padding: 0 2rem;
    list-style-type: disc;
  }
  .ProseMirror ol {
    padding: 0 2rem;
    list-style-type: decimal;
  }
  .ProseMirror li {
    padding: 0.25rem 0;
  }

  .ProseMirror h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  .ProseMirror h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  .ProseMirror h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  .ProseMirror h4 {
    display: block;
    font-size: 1em;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  .ProseMirror h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  .ProseMirror h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  .ProseMirror code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  .ProseMirror pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }

  .ProseMirror code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }
  
  .ProseMirror img {
    max-width: 100%;
    height: auto;
  }

  .ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid #707070;
  }

  .ProseMirror hr {
    border: none;
    border-top: 2px solid #717171;
    margin: 2rem 0;
  }
